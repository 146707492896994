import { Box, Typography } from '@mui/material';
import {
  ColumnsPlusRight,
  ArrowSquareIn,
  Atom,
  Binoculars,
  FlowArrow,
  Function,
  IdentificationCard,
  ListBullets,
  NumberSquareThree,
  Path,
  SelectionPlus,
  SlidersHorizontal,
  TreeStructure,
  TrendUp,
  AlignLeft,
  AlignRight,
  AmazonLogo,
  ArrowsSplit,
  Article,
  ArticleNyTimes,
  CubeTransparent,
  FrameCorners,
  GridNine,
  ImagesSquare,
  ListMagnifyingGlass,
  MusicNotes,
  ReadCvLogo,
  SpeakerSimpleHigh,
  Sticker,
  Table,
  TextItalic,
  Video,
  Waveform,
  Memory,
  ArchiveTray,
  Bank,
  ArrowSquareOut,
  Database,
  CodeBlock,
  Bulldozer,
  UsersThree,
} from '@phosphor-icons/react';
import { useAuthStore } from '../store/storeAuth';

export const Pro = ({
  title,
  isApiCaller,
}: {
  title?: string;
  isApiCaller?: boolean;
}) => {
  const { user } = useAuthStore(state => state);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: '5px',
        alignItems: 'center',
      }}
    >
      <Typography>{title}</Typography>
      {(user?.plan === 'Starter' ||
        user?.plan === 'Intro' ||
        (isApiCaller && user?.plan === 'Advanced')) && (
        <Typography
          color="white"
          fontWeight={'bold'}
          fontSize={'10px'}
          sx={{
            backgroundColor: '#3247D4',
            padding: '2px 5px',
            borderRadius: '5px',
          }}
        >
          PRO
        </Typography>
      )}
    </Box>
  );
};

const typesList = [
  {
    label: 'Ai Text Generator',
    type: 'commandTextNode',
    btn: 'Ai Text Generator',
    bgColor: 'purple',
    tooltip: 'Create original text or code snippets using Generative Ai.',
    icon: <ArticleNyTimes />,
  },
  {
    label: 'Text Box',
    type: 'textBox',
    btn: 'Text Box',
    bgColor: 'purple',
    tooltip: 'Add and link text for inputs or outputs in your workflow.',
    icon: <Article />,
  },
  {
    label: 'Rich Text Box',
    type: 'richTextBox',
    btn: 'Rich Text Box',
    bgColor: 'purple',
    tooltip: 'Display styled text for visually structured inputs or outputs.',
    icon: <TextItalic />,
  },
  {
    label: 'Display Box',
    type: 'outputController',
    btn: 'Display Box',
    bgColor: 'purple',
    tooltip: 'Display output content and download it in different formats.',
    icon: <ReadCvLogo />,
  },

  {
    label: 'Sticky Note',
    type: 'stickyNote',
    btn: 'Sticky Note',
    bgColor: 'purple',
    tooltip: 'Add notes to your workflow for reference or collaboration.',
    icon: <Sticker />,
  },

  {
    label: 'Ai Image Generator',
    type: 'imageGenerator',
    btn: 'Ai Image Generator',
    bgColor: 'blue',
    tooltip: 'Create original images using Generative Ai.',
    icon: <ImagesSquare />,
  },

  {
    label: 'Ai Image Reader',
    type: 'imageReaderNode',
    btn: 'Ai Image Reader',
    bgColor: 'blue',
    tooltip: 'Extract text and data from images using Ai.',
    icon: <FrameCorners />,
  },
  {
    label: 'Ai Video Generator',
    type: 'animationNode',
    btn: 'Ai Video Generator',
    bgColor: 'blue',
    tooltip: 'Transform text into engaging video content using Generative Ai.',
    icon: <Video />,
  },

  {
    type: 'voiceBoxNode',
    label: 'Ai Voice Generator',
    btn: 'Ai Voice Generator',
    bgColor: 'green',
    tooltip:
      'Operational system that communicates with Ai systems to generate human VOICE or dialog.',
    icon: <SpeakerSimpleHigh />,
  },
  {
    label: 'Ai Audio Transcriber',
    type: 'audioReaderNode',
    btn: 'Ai Audio Transcriber',
    bgColor: 'green',
    tooltip: 'Convert audio to text.',
    icon: <Waveform />,
  },
  {
    label: 'Ai Music Generator',
    type: 'commandMusicNode',
    btn: 'Ai Music Generator',
    bgColor: 'green',
    tooltip: 'Create original music using Ai.',
    icon: <MusicNotes />,
  },

  {
    label: 'Data Table Builder',
    type: 'tableProcessor',
    btn: 'Data Table Builder',
    bgColor: 'orange',
    tooltip: 'Create custom structured data tables.',
    icon: <GridNine />,
  },

  {
    label: 'Small Memory ',
    type: 'fluxObject',
    btn: 'Small Memory',
    bgColor: 'orange',
    tooltip: 'Save and recall flow outputs for easy access by Ai Generators.',
    icon: <ArchiveTray />,
  },
  {
    label: 'Large Memory',
    type: 'commandContentNode',
    btn: 'Large Memory',
    bgColor: 'orange',
    tooltip:
      'Store and manage large data sets easily with secure, scalable storage.',
    icon: <Memory />,
  },
  {
    label: 'Stored Data Caller',
    type: 'storedDataCaller',
    btn: 'Stored Data Caller',
    bgColor: 'orange',
    tooltip: 'Access and utilize saved data in your documents.',
    // disabled: true,
    icon: <Table />,
  },
  {
    label: 'Table Output',
    type: 'outputTable',
    btn: 'Table Output',
    bgColor: 'orange',
    tooltip: 'Display content generated by Ai in a table format.',
    icon: <ColumnsPlusRight />,
  },

  {
    type: 'nodesBox',
    label: 'Object Caller',
    btn: 'Object Caller',
    bgColor: 'orange',
    // disabled: true,
    tooltip: 'Access and utilize saved objects in your flows.',
    icon: <CubeTransparent />,
  },

  {
    type: 'deepRefNode',
    label: 'Flow Data Caller',
    btn: 'Flow Data Caller',
    bgColor: 'orange',
    // disabled: true,
    tooltip:
      'Gather content and data from within any flow or multiple flows at once.',
    icon: <TreeStructure />,
  },

  {
    label: 'Ai Text Summarizer',
    type: 'documentSummarizer',
    btn: 'Ai Text Summarizer',
    bgColor: 'orange',
    tooltip:
      'Condense long text into short, digestible summaries using advanced Ai methods.',
    icon: <ListBullets />,
  },

  {
    btn: 'Web Search',
    bgColor: 'silver',
    type: 'webSearch',
    label: 'Web Search',
    tooltip: 'Search the internet using top search engines.',
    icon: <Binoculars />,
  },

  {
    btn: 'Webpage Scraper',
    bgColor: 'silver',
    type: 'webCrawling',
    label: 'Webpage Scraper',
    tooltip: 'Gather data from web pages to feed into your workflows.',
    icon: <ListMagnifyingGlass />,
  },
  {
    btn: 'Web Amazon',
    bgColor: 'silver',
    type: 'webAmazon',
    label: 'Web Amazon',
    tooltip: 'Gather data from web pages for amazon.',
    icon: <AmazonLogo />,
  },
  {
    btn: 'Search Trends',
    bgColor: 'silver',
    type: 'webTrends',
    label: 'Search Trends',
    tooltip: 'Track and compare search term popularity over time.',
    icon: <TrendUp />,
  },
  {
    btn: 'US Census Data',
    bgColor: 'silver',
    type: 'usCensusNode',
    label: 'US Census Data',
    tooltip:
      'Quickly retrieve demographic statistics from the US Census Bureau.',
    icon: <IdentificationCard />,
  },
  {
    btn: 'SEC Data',
    bgColor: 'silver',
    type: 'secApiNode',
    label: 'SEC Data',
    tooltip: 'Access financial filings and market data from the SEC.',
    icon: <Bank />,
  },

  {
    label: 'Sequence Node',
    type: 'arrayNode',
    btn: 'Sequence Node',
    bgColor: 'red',
    tooltip:
      'Splits content into segments for individual processing in workflows',
    icon: <Path />,
  },

  {
    label: 'Functions',
    type: 'mathFunction',
    btn: 'Functions ',
    bgColor: 'red',
    tooltip: `Perform calculations, custom formulas or apply mathematical operations to content.`,
    icon: <Function />,
  },
  {
    label: 'Scripting',
    type: 'scripting',
    btn: 'Scripting ',
    bgColor: 'red',
    tooltip: `Write scripts using JavaScript or Python.`,
    icon: <CodeBlock />,
  },
  {
    type: 'conditionNode',
    label: 'Decision Paths',
    btn: 'Decision Paths',
    bgColor: 'red',
    tooltip: 'Determine workflow paths based on if-then logic',
    // disabled: true,
    icon: <ArrowsSplit />,
  },

  {
    label: 'Ai Model Switch',
    type: 'dynamicSettingsNode',
    btn: 'Ai Model Switch',
    bgColor: 'red',
    tooltip:
      'Dynamically switch Ai systems, models, or parameters in your workflow.',
    icon: <SlidersHorizontal />,
  },
  {
    label: 'Process Counter',
    type: 'counterNode',
    btn: 'Process Counter',
    bgColor: 'red',
    tooltip: 'Track flow passes for logic automation.',
    // disabled: true,
    icon: <NumberSquareThree />,
  },
  {
    label: 'Grouping',
    type: 'nodesGroup',
    btn: 'Grouping',
    bgColor: 'red',
    tooltip:
      'Organize your workflow by grouping related tasks for clarity and efficiency.',
    icon: <SelectionPlus />,
  },
  {
    label: 'Data Input',
    type: 'varInputNode',
    btn: 'Data Input',
    bgColor: 'violet',
    tooltip:
      'Input content and data into your flow from APIs or other external sources. ',
    icon: <ArrowSquareIn />,
  },

  {
    label: 'Data Output',
    type: 'outputObjectNode',
    btn: 'Data Output',
    bgColor: 'violet',
    tooltip:
      'Collect ouputs from a flow and return them to an API call, email, or other external sources.',
    icon: <ArrowSquareOut />,
  },

  {
    title: 'Flow Caller',
    label: 'Flow Caller',
    type: 'fluxBox',
    btn: 'Flow Caller ',
    bgColor: 'violet',
    tooltip:
      'Execute other flows, including sending and retrieving data, as part of a workflow.',
    icon: <FlowArrow />,
  },

  {
    label: 'Api Caller',
    type: 'apiCaller',
    btn: <Pro title="API Caller" isApiCaller={true} />,
    bgColor: 'violet',
    tooltip: 'Connect to external services to retrieve or send data.',
    icon: <Atom />,
  },

  {
    label: 'Flow Creator',
    type: 'nodesAdd',
    btn: 'Flow Creator',
    bgColor: 'violet',
    tooltip: 'Flow automation allows you to automate your workflows.',
    icon: <Bulldozer />,
  },

  {
    title: 'Api Config',
    label: 'Api Config',
    btn: 'Api Config ',
    bgColor: 'violet',
    tooltip: 'Shows the API configuration',
    // icon: <Wrench />
  },

  {
    label: 'Pull Data',
    type: 'pullData',
    btn: 'Pull Data',
    bgColor: 'orange',
    tooltip: 'Pull Data',
    icon: <AlignRight />,
  },

  {
    label: 'Push Data',
    type: 'pushData',
    btn: 'Push Data',
    bgColor: 'orange',
    tooltip: 'Push Data',
    icon: <AlignLeft />,
  },

  {
    label: 'SQL Database',
    type: 'sqlQuerier',
    btn: <Pro title="SQL Database" />,
    bgColor: 'orange',
    tooltip:
      'Connect to external sql based databases to retrieve or send data.',
    icon: <Database />,
  },

  {
    label: 'File Save',
    type: 'fileSave',
    btn: 'File Save',
    bgColor: 'orange',
    tooltip: 'File Save',
    icon: <ArchiveTray />,
  },
  // {
  //   bgColor: 'violet',
  //   text: replacedText,
  // },
];

export const types = (() => {
  if (process.env.REACT_APP_ENV === 'production') {
    return typesList;
  }

  return [
    ...typesList,
    {
      label: 'HubSpot CRM',
      type: 'hubSpotCRM',
      btn: 'HubSpot CRM',
      bgColor: 'orange',
      tooltip: 'HubSpot CRM',
      icon: <UsersThree />,
    }
  ]
})();