import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ReactComponent as GoogleIcon } from '../../assets/icons/google.svg';
import { ReactComponent as GoogleSheetsDocsIcon } from '../../assets/icons/GoogleSheetsDocs.svg';
import { ReactComponent as Connected } from '../../assets/icons/connected.svg';
import { ReactComponent as AccessTimedOutIcon } from '../../assets/icons/AccessTimedOut.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/Trash.svg';
import { ReactComponent as MoreIcon } from '../../assets/icons/more.svg';
import { TokenResponse, useGoogleLogin } from '@react-oauth/google';
import { AuthService } from '../../service/AuthService';
import { LocalStorageService } from '../../service/LocalStorageService';
import {
  NotificationType,
  useNotificationStore,
} from '../../store/storeNotifications';
import {
  getConnectedEmail,
  isGoogleSheetsAccessTokenValid,
} from '../GoogleSheetsIntegration/helpers';
import LogoutModal from './LogoutModal';
import { User } from '../../util/Types/User';
import { UserService } from '../../service/UserService';
import { useStoreGoogleData } from '../../store/storeGoogleData';
import HubSpotIntegration from '../HubSpotIntegration/HubSpotIntegration';

const scope =
  'https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.readonly https://www.googleapis.com/auth/spreadsheets.readonly https://www.googleapis.com/auth/documents https://www.googleapis.com/auth/documents.readonly https://www.googleapis.com/auth/presentations https://www.googleapis.com/auth/presentations.readonly';

enum ConnectionState {
  NotConnected = 'Not Connected',
  JustConnected = 'Just Connected',
  Connected = 'Connected',
  AccessTimedOut = 'Access Timed Out',
}

type LoginSuccessTokenResponse = Omit<
  TokenResponse,
  'error' | 'error_description' | 'error_uri'
>;
type LoginErrorResponse = Pick<
  TokenResponse,
  'error' | 'error_description' | 'error_uri'
>;

type Props = {
  authUser: User;
  setSelectedMenuItem?:
    | React.Dispatch<React.SetStateAction<string>>
    | undefined;
};

const Integrations = ({ authUser }: Props) => {
  const setNotification = useNotificationStore(state => state.setNotification);
  const {
    setGoogleRefreshToken,
    googleRefreshToken,
    setGoogleAccessToken,
    googleAccessToken,
  } = useStoreGoogleData();

  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [connectionState, setConnectionState] = useState<ConnectionState>(
    ConnectionState.NotConnected,
  );
  const [showLogoutBox, setShowLogoutBox] = useState(false);
  const [connectedEmail, setConnectedEmail] = useState('');

  useEffect(() => {
    const getConnectedEmailHandler = async () => {
      if (
        connectionState === ConnectionState.Connected ||
        connectionState === ConnectionState.JustConnected
      ) {
        if (googleAccessToken) {
          try {
            const connectedEmail = await getConnectedEmail(googleAccessToken);
            setConnectedEmail(connectedEmail);
          } catch (error) {
            setNotification({
              type: NotificationType.Error,
              message: 'Something went wrong fetching connected email',
            });
          }
        }
      }
    };

    getConnectedEmailHandler();
  }, [connectionState]);

  const refreshAuthToken = async (refreshToken: string) => {
    try {
      const newTokens = await AuthService.googleRefreshToken({
        refresh_token: refreshToken,
      });
      setGoogleAccessToken(newTokens.data.access_token);
      setGoogleRefreshToken(newTokens.data.refresh_token);

      setConnectionState(ConnectionState.Connected);
    } catch (refreshError) {
      // localStorage.removeItem('googleSheetsAccessToken');
      // localStorage.removeItem('googleRefreshToken');
      // setConnectionState(ConnectionState.NotConnected);
    }
  };

  useEffect(() => {
    const validateExistingGoogleSheetsAccessToken = async () => {
      if (
        googleAccessToken &&
        (await isGoogleSheetsAccessTokenValid(googleAccessToken))
      ) {
        setConnectionState(ConnectionState.Connected);
      } else if (googleRefreshToken) {
        await refreshAuthToken(googleRefreshToken);
      }
    };

    validateExistingGoogleSheetsAccessToken();
  }, []);

  const logoutHandler = async () => {
    LocalStorageService.removeItem('googleSheetsAccessToken');
    LocalStorageService.removeItem('googleRefreshToken');
    setConnectionState(ConnectionState.NotConnected);
    setShowLogoutBox(false);
    setShowLogoutModal(false);
    setGoogleAccessToken(null);
    setGoogleRefreshToken(null);
    await UserService.updateUser({
      googleAccessToken: null,
      googleRefreshToken: null,
    });
  };

  const loginSuccessHandler = (tokenResponse: LoginSuccessTokenResponse) => {
    const googleSheetsAccessToken = tokenResponse.access_token;

    setConnectionState(ConnectionState.JustConnected);
    setGoogleAccessToken(googleSheetsAccessToken);
  };

  const loginErrorHandler = (errorResponse: LoginErrorResponse) => {
    let errorMessage = 'Something went wrong!';
    if (errorResponse.error_description) {
      errorMessage = errorResponse.error_description;
    }

    setNotification({
      type: NotificationType.Error,
      message: errorMessage,
    });
  };

  const connectHandler = useGoogleLogin({
    onSuccess: async codeResponse => {
      const tokens = await AuthService.getGoogleRefreshToken({
        code: codeResponse.code,
        // redirect_uri: 'test',
      });
      setGoogleAccessToken(tokens.data.access_token);
      setGoogleRefreshToken(tokens.data.refresh_token);
      await UserService.updateUser({
        googleAccessToken: tokens.data.access_token,
        googleRefreshToken: tokens.data.refresh_token,
      });

      const loginSuccessTokens: TokenResponse = {
        access_token: tokens.data.access_token,
        expires_in: tokens.data.expiry_date,
        prompt: 'consent',
        token_type: '',
        scope: scope,
      };

      loginSuccessHandler(loginSuccessTokens);
    },
    onError: loginErrorHandler,
    scope,
    flow: 'auth-code',
  });

  const renderLogoutBox = () => {
    if (showLogoutBox) {
      return (
        <Box
          sx={{
            position: 'absolute',
            top: '20%',
            right: '-105px',
            boxShadow: '2px 4px 35px 0px rgba(0, 0, 0, 0.25)',
            background: 'rgba(255, 255, 255, 1)',
            padding: '20px',
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <TrashIcon />
          <Typography onClick={() => setShowLogoutModal(true)}>
            Log out
          </Typography>
        </Box>
      );
    } else {
      return null;
    }
  };

  const renderConnectionState = () => {
    if (connectionState === ConnectionState.NotConnected) {
      return (
        <Box
          sx={{
            width: 'min-content',
            minWidth: '250px',
            border: '1px solid rgba(196, 207, 255, 1)',
            padding: '24px',
            borderRadius: '8px',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              borderBottom: '.5px solid rgba(234, 236, 240, 1)',
            }}
          >
            <GoogleIcon />
            <Typography
              sx={{
                color: 'rgba(71, 84, 103, 1)',
                fontWeight: 600,
                fontSize: '12px',
                lineHeight: '19.2px',
                letterSpacing: '0.15px',
              }}
            >
              Google Account
            </Typography>
          </Box>

          <Typography
            sx={{
              color: 'rgba(102, 112, 133, 1)',
              fontSize: '12px',
              lineHeight: '19.2px',
              letterSpacing: '0.15px',
              marginTop: '20px',
            }}
          >
            Easily sync Google Account in FluxPrompt
          </Typography>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <GoogleSheetsDocsIcon />

            <Typography
              sx={{
                color: 'rgba(102, 112, 133, 1)',
                fontSize: '12px',
                lineHeight: '14px',
                marginTop: '15px',
              }}
            >
              You'll grant access to Google Docs and Sheets.
            </Typography>
          </Box>

          <Button
            variant="text"
            sx={{
              color: 'rgba(54, 80, 222, 1)',
              fontSize: '12px',
              lineHeight: '14px',
              fontWeight: 400,
              marginTop: '10px',
            }}
            onClick={() => connectHandler()}
          >
            Connect
          </Button>
        </Box>
      );
    }

    if (connectionState === ConnectionState.JustConnected) {
      return (
        <Box
          sx={{
            width: 'min-content',
            minWidth: '250px',
            border: '1px solid rgba(196, 207, 255, 1)',
            padding: '24px',
            borderRadius: '8px',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              borderBottom: '.5px solid rgba(234, 236, 240, 1)',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <GoogleIcon />

              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  sx={{
                    color: 'rgba(71, 84, 103, 1)',
                    fontWeight: 600,
                    fontSize: '12px',
                    lineHeight: '19.2px',
                    letterSpacing: '0.15px',
                  }}
                >
                  Google Account
                </Typography>
                <Typography
                  sx={{
                    fontSize: '12px',
                    lineHeight: '19.2px',
                    letterSpacing: '0.15px',
                    color: 'rgba(102, 112, 133, 1)',
                  }}
                >
                  {connectedEmail}
                </Typography>
              </Box>
              <Box
                sx={{ marginLeft: 'auto', cursor: 'pointer' }}
                onClick={() => setShowLogoutBox(show => !show)}
              >
                <MoreIcon />
                {renderLogoutBox()}
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <Connected />
              <Typography
                sx={{
                  color: 'rgba(102, 112, 133, 1)',
                  fontSize: '12px',
                  lineHeight: '19.2px',
                  letterSpacing: '0.15px',
                  marginTop: '20px',
                }}
              >
                You are successfully logged in to your Google account.
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <GoogleSheetsDocsIcon />

            <Typography
              sx={{
                color: 'rgba(102, 112, 133, 1)',
                fontSize: '12px',
                lineHeight: '14px',
                marginTop: '15px',
              }}
            >
              You'll grant access to Google Docs and Sheets.
            </Typography>
          </Box>
        </Box>
      );
    }

    if (connectionState === ConnectionState.Connected) {
      return (
        <Box
          sx={{
            width: 'min-content',
            minWidth: '250px',
            border: '1px solid rgba(196, 207, 255, 1)',
            padding: '24px',
            borderRadius: '8px',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              borderBottom: '.5px solid rgba(234, 236, 240, 1)',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <GoogleIcon />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography
                  sx={{
                    color: 'rgba(71, 84, 103, 1)',
                    fontWeight: 600,
                    fontSize: '12px',
                    lineHeight: '19.2px',
                    letterSpacing: '0.15px',
                  }}
                >
                  Google Account
                </Typography>
                <Typography
                  sx={{
                    fontSize: '12px',
                    lineHeight: '19.2px',
                    letterSpacing: '0.15px',
                    color: 'rgba(102, 112, 133, 1)',
                  }}
                >
                  {connectedEmail}
                </Typography>
              </Box>
              <Box
                sx={{ marginLeft: 'auto', cursor: 'pointer' }}
                onClick={() => setShowLogoutBox(show => !show)}
              >
                <MoreIcon />
                {renderLogoutBox()}
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <GoogleSheetsDocsIcon />

            <Typography
              sx={{
                color: 'rgba(102, 112, 133, 1)',
                fontSize: '12px',
                lineHeight: '14px',
                marginTop: '15px',
              }}
            >
              You are currently connected to Google Docs and Sheets.
            </Typography>
          </Box>
        </Box>
      );
    }

    if (connectionState === ConnectionState.AccessTimedOut) {
      return (
        <Box
          sx={{
            width: 'min-content',
            minWidth: '250px',
            border: '1px solid rgba(196, 207, 255, 1)',
            padding: '24px',
            borderRadius: '8px',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              borderBottom: '.5px solid rgba(234, 236, 240, 1)',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <GoogleIcon />
              <Typography
                sx={{
                  color: 'rgba(71, 84, 103, 1)',
                  fontWeight: 600,
                  fontSize: '12px',
                  lineHeight: '19.2px',
                  letterSpacing: '0.15px',
                }}
              >
                Google Account
              </Typography>

              <Box
                sx={{ marginLeft: 'auto', cursor: 'pointer' }}
                onClick={() => setShowLogoutBox(show => !show)}
              >
                <MoreIcon />
                {renderLogoutBox()}
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <AccessTimedOutIcon />
              <Typography
                sx={{
                  color: 'rgba(102, 112, 133, 1)',
                  fontSize: '12px',
                  lineHeight: '19.2px',
                  letterSpacing: '0.15px',
                  marginTop: '20px',
                }}
              >
                Access timed out. Reconnect to continue using Google connections
                in your workflows.
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <GoogleSheetsDocsIcon />

            <Typography
              sx={{
                color: 'rgba(102, 112, 133, 1)',
                fontSize: '12px',
                lineHeight: '14px',
                marginTop: '15px',
              }}
            >
              You'll grant access to Google Docs and Sheets.
            </Typography>
          </Box>

          <Button
            variant="text"
            sx={{
              color: 'rgba(54, 80, 222, 1)',
              fontSize: '12px',
              lineHeight: '14px',
              fontWeight: 400,
              marginTop: '10px',
            }}
            onClick={() => connectHandler()}
          >
            Connect
          </Button>
        </Box>
      );
    }
  };

  return (
    <>
      {showLogoutModal && (
        <LogoutModal
          onHide={() => setShowLogoutModal(false)}
          logoutHandler={logoutHandler}
        />
      )}

      <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 99,
          backgroundColor: 'white',
          borderBottom: '1px solid #D0D5DD',
          display: 'flex',
          height: authUser.isUserCompany ? '67px' : '64px',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            color: '#000',
            fontFamily: 'Roboto',
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '32px',
            margin: '24px',
          }}
        >
          Integrations
        </Typography>
      </Box>

      <Box
        sx={{
          padding: '24px 24px 16px 24px',
          gap: '32px',
        }}
      >
        <Typography
          sx={{
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '28.8px',
            letterSpacing: '0.15px',
            color: 'rgba(71, 84, 103, 1)',
          }}
        >
          All Connections
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs="auto">
            {renderConnectionState()}
          </Grid>
          {process.env.REACT_APP_ENV !== 'production' && (
            <Grid item xs="auto">
              <HubSpotIntegration />
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default Integrations;
