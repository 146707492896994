import React, { memo, useCallback, useEffect, useState } from 'react';
import { Position, useEdges, useReactFlow } from 'reactflow';
import { NodeProps } from '../../util/Types/NodeProps';
import jsPDF from 'jspdf';
import DataVisualizationModal from '../DataVisualizationModal';
import {
  Button,
  Select,
  MenuItem,
  Box,
  Typography,
  Switch,
  FormLabel,
  Divider,
} from '@mui/material';
import InputHandle from '../UI/InputHandle/InputHandle';
import { nodeColorBasedOnType } from '../UI/Node/nodeConstants';
import Node from '../UI/Node/Node';
import { arrayDataToText } from '../../util/dataToText';
import { isActiveEdge } from '../../util/findActiveHandle';
import { useAuthStore } from '../../store/storeAuth';

import { TextComponent } from '../UI/PreviewModal/PreviewModal';
import { useNotificationStore } from '../../store/storeNotifications';
import { useDebounceEffect } from '../../util/useDebounceEffect';
import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import useFlowsStore from '../../store/storeFlows';
import { useParams } from 'react-router-dom';
import { styled } from 'styled-components';
import OutputTextarea from '../UI/OutputTextarea/OutputTextarea';
import { axiosInstance } from '../../service/ApiService';

const saveTypes = [
  {
    name: 'Save as .txt',
    extension: '.txt',
    fileType: 'txt',
    mimeType: 'text/plain',
  },
  {
    name: 'Save as .html',
    extension: '.html',
    fileType: 'html',
    mimeType: 'text/html',
  },
  {
    name: 'Save as .json',
    extension: '.json',
    fileType: 'json',
    mimeType: 'application/json',
  },
  {
    name: 'Save as .csv',
    extension: '.csv',
    fileType: 'csv',
    mimeType: 'text/csv',
  },
  {
    name: 'Save as .xml',
    extension: '.xml',
    fileType: 'xml',
    mimeType: 'application/xml',
  },
  {
    name: 'Save as .yaml',
    extension: '.yaml',
    fileType: 'yaml',
    mimeType: 'text/yaml',
  },
  {
    name: 'Save as .pdf',
    extension: '.pdf',
    fileType: 'pdf',
    mimeType: 'application/pdf',
  },
  {
    name: 'Save as .docx',
    extension: '.docx',
    fileType: 'docx',
    mimeType:
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  },
  {
    name: 'Save as .png',
    extension: '.png',
    fileType: 'png',
    mimeType: 'image/png',
  },
  {
    name: 'Save as .jpg',
    extension: '.jpg',
    fileType: 'jpg',
    mimeType: 'image/jpeg',
  },
];

const BlueSwitch = styled(Switch)(() => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: 'rgba(54, 80, 222, 1)',
  },
}));

function OutputControllerNode({ data, type, id }: NodeProps) {
  const [savingType, setSavingType] = useState('Save as .txt');
  const fileName = data?.label || 'label';
  const [showDataModal, setShowDataModal] = useState(false);
  const setNotification = useNotificationStore(state => state.setNotification);
  const edges: any = useEdges();
  const [size, setSize] = useState({
    height: data?.size?.height || 600,
    width: data?.size?.width || 800,
  });
  const { setNodes: setNodeState } = useReactFlow();
  const { setSaving, setSchema } = useFlowsStore(state => state);
  const { flowId } = useParams();
  const [filter, setFilter] = useState(data.filter || 'all');

  const onChange = useCallback(
    (e: { target: { value: React.SetStateAction<string> } }) => {
      setSavingType(e.target.value);
    },
    [setSavingType],
  );

  const onSaveOutputChange = async () => {
    const response = data?.textData
      ?.map((item: { text: string }) => item.text)
      .join(' \n');

    const findType = saveTypes.find(type => type.name === savingType);
    if (!findType) {
      return;
    }
    const { data: res } = await axiosInstance.post('/upload/text', {
      text: response || '',
      fileType: findType?.fileType,
    });

    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      id,
      flowId,
      objectCallerData: user?.objects,
      objectData: {
        saveOutput: !data.saveOutput,
        fileLink: res.data,
      },
      setSchema,
      setSaving,
    });
  };

  const onChangeFilter = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setFilter(event.target.value);
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      id,
      setSaving,
      changeType: 'changeText',
      flowId,
      objectData: {
        filter: event.target.value,
      },
      setSchema,
    });
  };

  useEffect(() => {
    const getAutoLink = async () => {
      if (data.saveOutput) {
        const response = data?.textData
          ?.map((item: { text: string }) => item.text)
          .join(' \n');

        const findType = saveTypes.find(type => type.name === savingType);
        if (!findType) {
          return;
        }
        try {
          const { data: res } = await axiosInstance.post('/upload/text', {
            text: response || '',
            fileType: findType?.fileType,
          });
          nodeDataAutoSaveDynamic({
            newEdges: edges,
            setNodeState,
            id,
            flowId,
            objectCallerData: user?.objects,
            objectData: {
              fileLink: res.data,
            },
            setSchema,
            setSaving,
          });
        } catch (error: any) {
          setNotification({ type: 'error', message: error.message });
        }
      }
    };
    getAutoLink();
  }, [data.textData]);

  const onChangeInput = useCallback(
    (content: {
      target: { value: string | number; name: string | number };
    }) => {
      setSize(state => ({
        ...state,
        [content.target.name]: Number(content.target.value),
      }));
    },
    [],
  );

  const onCopy = (text: string) => {
    navigator.clipboard.writeText(text);

    setNotification({
      type: 'success',
      message: 'Text copied to clipboard',
    });
  };

  useDebounceEffect(
    () => {
      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setNodeState,
        id,
        setSaving,
        changeType: 'changeText',
        flowId,
        objectData: {
          size: {
            height: size.height,
            width: size.width,
          },
        },
        setSchema,
      });
    },
    [size],
    500,
  );

  const onDownload = useCallback(
    (e: any) => {
      const element = document.createElement('a');

      const findType = saveTypes.find(type => type.name === savingType);
      if (!findType) {
        return;
      }

      let outputText =
        data?.['textData']?.length > 0
          ? arrayDataToText(data?.['textData'])
          : '';

      if (findType.extension === '.pdf') {
        const doc = new jsPDF();

        doc.text(outputText, 10, 10);

        doc.save(`${fileName ? fileName : 'output'}.pdf`);

        return;
      }
      const file = new Blob([outputText], {
        type: findType.mimeType,
      });

      element.href = URL.createObjectURL(file);
      element.download = `${fileName ? fileName : 'output'}${
        findType.extension
      }`;

      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    },
    [saveTypes, data?.textData, fileName, savingType],
  );

  const nodeType =
    nodeColorBasedOnType[type as keyof typeof nodeColorBasedOnType];
  const findColor = nodeType.colors;
  const { user } = useAuthStore(state => state);
  return (
    <Node
      id={id}
      data={data}
      isLoading={false}
      type={type}
      size={size || data?.size}
      showTokensUsed={false}
    >
      {' '}
      <Box p="16px">
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '10px',
            }}
          >
            <Box>
              <FormLabel>Save output and generate file link.</FormLabel>
              <BlueSwitch
                value={'Monthly'}
                checked={data?.saveOutput}
                onChange={onSaveOutputChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Box>
            <Select
              value={filter}
              onChange={onChangeFilter}
              className="nodrag"
              size="small"
              sx={{
                '& .MuiList-root': {
                  backgroundColor:
                    user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                },
                '& .MuiSvgIcon-root': {
                  color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                },
                borderRadius: '8px',
                border: 'none',
                boxShadow: 'none',
                '.MuiOutlinedInput-notchedOutline': { border: 0 },
                bgcolor: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    '& .Mui-selected': {
                      backgroundColor:
                        user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                    },
                    backgroundColor:
                      user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                  },
                },
              }}
            >
              <MenuItem value="all">Intake all</MenuItem>
              <MenuItem value="code">Only Intake 'code'</MenuItem>
              <MenuItem value="table">Only Intake 'tables'</MenuItem>
            </Select>
            <Box>
              <input
                type="number"
                name="width"
                placeholder="Enter Width"
                min={200}
                value={size.width}
                onChange={onChangeInput}
                max={1000}
                className="nodrag"
                style={{
                  width: '60px',
                  border: `1px solid ${
                    user?.theme === 'dark' ? '#344054' : '#D0D5DD'
                  }`,
                  backgroundColor:
                    user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                  color: user?.theme === 'dark' ? '#98A2B3' : 'black',
                }}
              />
              <input
                type="number"
                name="height"
                min={200}
                placeholder="Enter Height"
                max={1000}
                value={size.height}
                onChange={onChangeInput}
                style={{
                  width: '60px',
                  border: `1px solid ${
                    user?.theme === 'dark' ? '#344054' : '#D0D5DD'
                  }`,
                  backgroundColor:
                    user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                  color: user?.theme === 'dark' ? '#98A2B3' : 'black',
                }}
                className="nodrag "
              />
            </Box>
          </Box>

          <br />
          {data?.textData?.map((item: any) => (
            <TextComponent
              key={item?.text?.slice(0, 100) + item.id}
              item={item}
              user={user}
              onCopy={onCopy}
              contentType={filter}
            />
          ))}
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={2}
        >
          <Button variant="contained" onClick={onDownload}>
            Download
          </Button>
          <Select
            onChange={onChange}
            value={savingType}
            name="saveTypes"
            className="nodrag"
            sx={{
              height: 'unset',
              '& .MuiList-root': {
                backgroundColor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
              },
              bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
              color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
              border: `2px solid ${
                user?.theme === 'dark' ? '#475467' : '#EAECF0'
              }`,
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  '& .Mui-selected': {
                    backgroundColor:
                      user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                  },
                  backgroundColor:
                    user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                },
              },
              style: {
                maxHeight: '700px',
              },
            }}
          >
            {saveTypes.map((saveType, index) => (
              <MenuItem key={index} value={saveType.name}>
                <Typography
                  sx={{
                    color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                  }}
                >
                  {saveType.name}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <Divider />
      <Box p="16px">
        {data.saveOutput && (
          <OutputTextarea
            value={data?.fileLink.src || 'No Link generated'}
            activeHandle={isActiveEdge(edges, id, 'fileLink', 'source')}
            labelName={'Link'}
            handleId={'fileLink'}
            nodeLabel={data?.label}
            type={type}
            findColor={findColor}
          />
        )}
      </Box>
      <Box>
        <DataVisualizationModal
          resData={''}
          showDataModal={showDataModal}
          setShowDataModal={setShowDataModal}
        />
      </Box>
      <InputHandle
        activeColor={findColor?.color}
        handleId={'output'}
        isActive={isActiveEdge(edges, id, 'output', 'source')}
        right={-12}
        top={'20%'}
        position={Position.Right}
        type="source"
      />
      <InputHandle
        activeColor={findColor?.color}
        handleId={'input'}
        isActive={isActiveEdge(edges, id, 'input', 'target')}
        left={-12}
        top={'50%'}
        position={Position.Left}
        type="target"
      />
    </Node>
  );
}

export default memo(OutputControllerNode);
